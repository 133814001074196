import Layout from "../components/layout/layout";
import { graphql } from "gatsby";
import React from "react";
import Parser from "../components/parser.js";

const Index = ({ data }) => {
  return (
    <Layout
      infos={{
        title: data.wpPage.title,
        postType: "index"
      }}
      seo={
        data.wpPage.seo
      }
    >
      <Parser content={ data.wpPage.content } subQueries={ data.wpPage.subQueries?.nodes || [] } />
    </Layout>
  );
};
export const pageQuery = graphql`
  query FrontPage {
    wpPage(isFrontPage: {eq: true}) {
      content
      title
      seo {
        canonicalUrl
        description
        openGraphDescription
        openGraphTitle
        openGraphType
        title
        twitterDescription
        twitterTitle
        noIndex
        excludeLocalSearch
        excludeFromArchive
        noArchive
        noFollow
        redirectUrl
        socialImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      subQueries {
        nodes {
          id
          relatedPostsIds
          posts {
            nodes {
              id
              excerpt
              uri
              title
              slug
              date
              categories {
                nodes {
                  slug
                  uri
                  id
                  name
                }
              }
              featuredImage {
                node {
                  altText
                  caption
                  localFile {
                    childImageSharp {
                      thumb: gatsbyImageData(width: 200)
                      small: gatsbyImageData(width: 400)
                      medium: gatsbyImageData(width: 800)
                      large: gatsbyImageData(width: 1000)
                      xl: gatsbyImageData(width: 1200)
                      full: gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default Index;
